.boxArticles_slider{
}

.boxArticles_slider ul,
.boxArticles_slider li{
	margin: 0;
	padding : 0;
}
.boxArticles_slider li:beforer{
	display: none;
}

		.boxArticles_slider .unArticle{
			font-size:0.8em;
			overflow:hidden;
			cursor: pointer;
			position: relative;
			display: block;
			z-index: 0;
			height: 100%;
		}
		
		.boxArticles_slider .libArticle{
			position: absolute;	
			z-index: 2;
			right: 0;
			left: 0;
			bottom: 0;
			margin: 0;
			min-height: 32px;
		}
		
		.boxArticles_slider a.imgArticle,
		.boxArticles_slider a.flashArticle{
			position: absolute;
			z-index: 1;
			margin: 0;
			top: 0;
			right: 0;
			left: 0;
			bottom: 48px;
		}
		
		.boxArticles_slider a.imgArticle{
			background-position: center center;
			background-repeat: no-repeat;
			
			-moz-background-size: auto 100%;
			-o-background-size: auto 100%;
			-webkit-background-size: auto 100%;
			background-size: auto 100%;
		}
		
		.boxArticles_slider a.imgArticle>img{
			display: none;
		}
		
.boxArticles_navigator{

}

	.boxArticles_navigator a{
		display: inline-block;
		background-color: #BBB;
		border: solid 2px #999;
		border-radius: 50%;
		width: 12px;
		height: 12px;
		margin: 2px;
	}
	
	.touch .boxArticles_navigator a{
		width: 24px;
		height: 24px;
		margin: 0 4px;
	}
	
	.boxArticles_navigator a.active{
		background-color: #FFF;
		border-color: #666;
	}
        